.viewTeamButton{
    border-radius: 25px;
    padding: 5px 10px !important;
    font-weight: 400;
    font-size: 0.8rem;
    background: linear-gradient(90deg, #181829 0%, #181829 35%);
    width: fit-content ;
    margin-left: auto;
  }

  /* MEDIA QUERY */
@media only screen and (max-width: 768px) {
  .viewTeamButton{
    margin: auto;
  }
}
