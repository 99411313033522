#pageDropDown{
    background-color: #2A54A0;
    border-radius: 15px;
    border-color: #0AA7FF;
    padding: 12px 24px;
}

thead {
    vertical-align: middle !important;
    
}

th{
    background: linear-gradient(180deg, #29A5DE 0%, #2B3C8E 100%) !important;
    color: white !important;
    align-items: center !important;
    padding: 15px !important;
    
}

td{
    background-color: transparent !important;
    color: white !important;
    border-style: none !important;
    /* text-align: center;  */
    vertical-align: middle;
    padding: 10px;
}

tr{
    /* background-color: #141414 !important; */
    background-color: #2A54A0 !important;
    color: white !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #181829 !important;
}