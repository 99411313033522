.MainContainer{
    width: 100%;
    height: fit-content;
}

.profileImage {
    max-width: 100%;
    height: 80px;
    object-fit: contain;
    border-radius: 4px;
}

.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.titleContainer{
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    align-items: center;
}

.mainTable{
    /* margin:20px */
    margin: 20px 0px;
}

.btnBg{
    background: linear-gradient(96.39deg, #FBBE21 6.05%, #E0931F 91.05%) !important;
    color: black
}

.cardBtn {
    background-image: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
    height: fit-content;
    padding: 4px 15px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
}

.searchContainer{
    display: flex;
    align-items: center;
}

.searchInput{
    width: 300px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #2B3C8E;
    padding: 5px;
}

.searchBtn {
    background: linear-gradient(180deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 15px;
}