.modalContainer {
  padding: 20px;
  width: 100%;
  max-width: 500px;
  height: fit-content;
  /* max-height: 500px; */
  max-height: 80vh;
  border-radius: 18px;
  background-color: #0d0d1c;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1000;
  overflow: scroll;
}

hr {
  border-top: 1px solid #48556f !important;
  width: 90%;
}

input::-ms-reveal {
  filter: invert(100%);
}

@media only screen and (max-width: 768px) {
  .modalContainer {
    max-width: 90vw;
  }
}

.custommodal {
  padding: 20px;
  width: 100%;
  max-width: 500px;
  height: fit-content;
  /* max-height: 500px; */
  max-height: 80vh;
  border-radius: 18px;
  background-color: #0d0d1c;
  color: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  position: relative;
  z-index: 1000;
  overflow: scroll;
}
.custommodal::-webkit-scrollbar {
  display: none;
}
.data {
  color: white;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
