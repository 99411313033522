.MainContainer {
  width: 100%;
  height: fit-content;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mainTable {
  /* margin:20px */
  margin: 20px 0px;
}
.btnBgban {
  background-color: red;
}
.btnBgunban {
  background: green !important;
}
