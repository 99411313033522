.select {
    /* background-color: #222232 !important; */
    background-color: rgb(255, 255, 255) !important;;
    border: 1px solid #363738 !important;
    border-radius: 18px !important;
    color: rgb(0, 0, 0) !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 5px 0px 15px 0px;
    width: 100%;

}

.select::placeholder {
    color: rgb(0, 0, 0) !important;
}

.select:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
}

select {
    background-image:
        linear-gradient(50deg, transparent 50%, white 60%),
        linear-gradient(125deg, white 40%, transparent 50%) !important;
    background-position:
        calc(100% - 20px) 20px,
        calc(100% - 15px) 20px,
        100% 0;
    background-size:
        6px 10px,
        6px 10px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
}

