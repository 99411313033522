.tournament {
  width: 100%;
  height: fit-content;
}

img.logo {
  width: 60px;
  height: 60px;
  border-radius: 100%;

  @media (max-width:991px) {
      width: 40px;
      height: 40px;
  }
}

.infoCard {
  border-radius: 10px;
  color: white;
  background-color: #848A93 !important;
}

.btnBg{
  background: linear-gradient(96.39deg, #FBBE21 6.05%, #E0931F 91.05%) !important;
  color: black
}

.searchContainer{
  display: flex;
  align-items: center;
}

.info {
  margin-left: 10px;

  .titleContainer,
  .descriptionContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      span.liveBadge {
          height: fit-content;
          padding: 4px 15px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #FF3C3C;
          border-radius: 18px;
          color: white;
          font-size: 10px;
      }

      span.deleteBadge {
          height: fit-content;
          padding: 4px 15px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ADD8E6;
          border-radius: 18px;
          color: red;
          font-size: 20px;
          font-weight: 900;
      }

      span.mapIcon {
          display: flex;
          margin-right: 5px;
          color: #246BFD;
      }
  }

  .descriptionContainer {
      padding-right: 5px;
  }
}

.backButton {
  background-image: linear-gradient(90deg, rgb(200, 200, 200) 0%, rgb(157, 157, 157) 35%);
  width: 100px;
  height: fit-content;
  padding: 4px 15px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
}

.deleteBadge {
  background-image: linear-gradient(0deg, rgb(134, 3, 3) 0%, rgb(255, 0, 0) 80%);
  width: 100px;
  height: fit-content;
  padding: 4px 15px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  color: white;
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
}

.editBadge {
  background-image: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
  width: 100px;
  height: fit-content;
  padding: 4px 15px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cardStyle {
  /* border: linear-gradient(to right, #29A5DE, #2B3C8E); */
  border-radius: 32px !important;
  /* background: linear-gradient(to right, #b92b27, #1565c0); */
  /* background-color: #0D0D1C; */
  background-image: url("/public/images/card_background.png");
  background-size: auto;
  background-repeat: no-repeat;
  color: white;
  padding: 16px !important;
  width: 100%;
  height: fit-content;
  background-color: #0d0d1c;
}

.viewButton {
  display: flex;
  background: linear-gradient(123.9deg, #1b76a0 24.41%, #3d55cf 94.01%) !important;
  border: none;
  white-space: nowrap;
  color:  white !important;
  font-size: 14px;
  font-weight: 400;
}

&:focus-visible,
&:focus-within {
  outline: none;
}

.avatarImage {
  width: 65px;
  height: 65px;
  margin-left: auto;
}

.cardTitle {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.badgeColor {
  background-color: red !important;
}

.cardBtn {
  border: 1px solid #0AA7FF;
}

.ViewTournament {
  width: 100%;
}

.topMargins {
  /* margin-top: 12; */
  margin-top: 12 !important;
  height: 100%;
}

.beforeTabs {
  background-color: #8d8d8d;
  border-radius: 25px;
  /* margin-top: 6px; */
  padding: 8px !important;
  overflow: auto;
  white-space: nowrap;
}

.panelBtn {
  border-radius: 25px;
  padding: 15px 25px !important;
  font-weight: 500;
  font-size: medium;
}

.orangeBg {
  background: linear-gradient(90deg, rgba(251, 190, 33, 1) 0%, rgba(224, 147, 31, 1) 35%);
  /* padding: 25px 30px !important; */
}

.blueGradiant {
  background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%);

}

.description {
  font-size: large;
}

td {
  padding: 10px;
}



/* DROP DOWN CSS CREDIT CARD */
/* The container <div> - needed to position the dropdown content */
.dropdown {
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 18px;
  position: relative;
  display: inline-block;
}

/* Style The Dropdown Button */
.dropBtn {
  width: 100%;
  border: 1px solid #ffffff;
  background-color: #222232;
  border-radius: 18px;
  color: rgb(0, 0, 0);
  padding: 8px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}


/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  /* display: none; */
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px;
  border: 1px solid #48556F;
  border-radius: 18px;
  bottom: 75px;
}

/* Links inside the dropdown */
.dropdownContent a {
  color: rgb(0, 0, 0);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.teamAvatar {
  width: 60px !important;
  height: 60px !important;
  margin: 10px;
}

.dateLabel {
  color: rgb(0, 0, 0) !important;;
  padding: 0px 10px;
  font-size: smaller;
}

.datePicker {
  /* background-color: #222232 !important; */
  background-color: rgb(255, 255, 255) !important;;
  border: 1px solid #2e2f31 !important;
  border-radius: 18px !important;
  color: rgb(0, 0, 0) !important;
  font-size: 16px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  line-height: 1.4 !important;
  padding: 10px;
  margin: 5px 0px 15px 0px;
  width: 100%;
}

.datePicker::-webkit-calendar-picker-indicator {
  filter: none;
  color: #0D0D1C;
}

.modalContainer {
  padding: 20px;
  width: 100%;
  max-width: 500px;
  height: fit-content;
  /* max-height: 500px; */
  max-height: 80vh;
  border-radius: 18px;
  background-color: #0D0D1C;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1000;
  overflow: scroll;
}

.item2 {
  grid-area: info;
  height: calc(100vh - 100px);
  overflow: hidden;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.spinner {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.text-danger {
  color: red !important;
}

@media (max-width:991px) {
  .item2 {
    height: fit-content;
    position: relative;
  }

  .subItem2 {
    display: none;
  }
}





/* MEDIA QUERY */
@media only screen and (max-width: 991px) {
  .topMargins {
    margin-top: 10px;
  }

  .topMargins>div>div>div {
    padding-top: 10px;
  }
}