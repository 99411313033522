.select{
    /* background-color: #222232 !important; */
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid #2e2f31 !important;
    border-radius: 18px !important;
    color: rgb(0, 0, 0) !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 5px 0px 15px 0px;
    width: 100%;

}

.select::placeholder {
    color: white !important;
}

.select:focus{
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}